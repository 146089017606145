// 
// list-group.scss
// Extended from Bootstrap
// 


// 
// Additional style for theme
// 

// list group borderless
.list-group-borderless {
  .list-group-item {
    border: none;
    padding: 0.425rem 0;
  }
  a.list-group-item {
    &:hover {
      background-color: transparent;
      color:  $primary;
    }
  }
}

// list group icon primary bg
.list-group-icon-primary-bg{
 .list-group-item{
    display: flex;
  } 
  i,
  span {
  color: $primary;
  background-color:shift-color($primary, -80%);
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
  margin-right: 10px;
  flex-shrink: 0;
  text-align: center;
  }
}