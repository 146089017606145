// 
// utilities.scss
// Theme component for utility classes (helper classes)

// text color on hover
.text-primary-hover {
  a {
    color: $gray-400;
    &:hover {
      color: $primary !important;
    }
  }
}

a.text-primary-hover, button.text-primary-hover {
  color: $white;
  &:hover {
    color: $primary !important;
  }
}

.text-primary-hover {
  li {
    color: $gray-400;
    &:hover {
      color: $primary !important;
    }
  }
}

// Filter
.grayscale {
  filter: grayscale(100%);
  transition: all .6s ease;
  &:hover {
    filter: none;
    -webkit-filter: grayscale(0%);
  }
}


// Icons fixed height and width
.icon-sm {
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  font-size: 75%;
}
.icon-md {
  width: 2.8rem;
  height: 2.8rem;
  line-height: 2.8rem;
  text-align: center;
  font-size: .8rem;
}
.icon-lg {
  width: 3.5rem;
  height: 3.5rem;
  line-height: 3.5rem;
  text-align: center;
  font-size: 1.2rem;
}
.icon-xl {
  width: 5rem;
  height: 5rem;
  line-height: 5rem;
  text-align: center;
  font-size: 1.9rem;
}

//  Border rounded Start 
.rounded-start-1{
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}