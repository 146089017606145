// 
// buttons.scss
// Extended from Bootstrap
// 

// 
// Additional styles for theme
// 

.btn {
  letter-spacing: 0.5px;
  position: relative;
  overflow: hidden;
  outline: 0;
  white-space: nowrap;
  margin-bottom: 6px;
}
.input-group .btn{
  margin-bottom: 0;
}
.btn-xs{
  padding: 0.4rem 0.6rem;
  font-size: 0.6rem;
  line-height: 1.2;
  border-radius: $btn-border-radius;
}

// button white
.btn-white {
  background: $white;
  color: $gray-800;
  &.active, &:active, &:focus, &:hover {
    background: $gray-200;
    color: $gray-800;
  }
}
.btn-outline-white {
  background: transparent;
  border-color: $white;
  color: $white;
  &.active, &:active, &:focus, &:hover {
    color: $gray-800;
    background: $white;
  }
}
.btn-outline-light{
  background: transparent;
  border-color: $gray-500;
  color: $body-color;
}
.btn-outline-light:hover,
.btn-outline-light:focus,
.btn-outline-light:active,
.btn-check:checked + .btn-outline-light
{
  background: $gray-400;
  border-color: $gray-400;
  color: $gray-800;
}

.btn-dark{
  background-color: $gray-900;
  &.active, &:active, &:focus, &:hover {
    background: $gray-800;
    color:$white; 
  }
}

.btn-light {
  background: shade-color($light, 10%);
  border-color: shade-color($light, 10%);
  &.active, &:active, &:focus, &:hover {
    background: shade-color($light, 20%);
    border-color: shade-color($light, 20%);
  }
}

// button round
.btn-round{
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
  padding: 0;
  text-align: center;
  i{
    margin:0; 
  }
  // Quick fix for play button centered
  .fa-play{
    padding-left: 3px;
  }
  &.btn-lg{
    height: 55px;
    width: 55px;
    line-height: 55px;
  }
  &.btn-sm{
    height: 32px;
    width: 32px;
    line-height: 30px;
    i{
      font-size: .6rem;
    }
  }
}

// White shadow
.btn-white-shadow {  
  transition: all 0.3s ease-in-out; 
  box-shadow: 0 0 0 8px rgba(255 255 255 / 0.4);
  background-color:$white; 
    &.active,
    &:focus{
    box-shadow: 0 0 0 8px rgba(255 255 255 / 0.4);
    }
}

// button soft
@mixin button-soft-variant($color, $soft-background: rgba($color, .1)) {
  color: $color;
  background-color: $soft-background;
  &:hover {
    color: color-contrast($color);
    background-color: $color;
  }
}
@each $color, $value in $theme-colors {
  .btn-#{$color}-soft {
    @include button-soft-variant($value);
  }
}