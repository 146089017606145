// 
// general.scss
// Theme general elements and helper styling


// Back to top
.back-top {
  cursor: pointer;
  position: fixed !important;
  bottom: 40px;
  right: 40px;
  display: block;
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  transform: translateY(50%);
  transition: $transition-base;
  background: rgba($primary, $soft-alpha + 0.1);
  color: $primary;
  border-radius: 50%;
  width: 52px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  i{
    font-size: 1.6rem;
    vertical-align: middle;
  }
  &:hover{
    background: $primary;
    color: $white;
  }
  &.back-top-show {
    visibility: visible;
    opacity: 1;
    transform: translateY(0%);
  }
}
@include media-breakpoint-down(md) {
  .back-top {
    bottom: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    i{
      font-size: 1rem;
    }
  }
}
 
// Overlay
.bg-overlay {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: $black;
  opacity: .5;
  z-index: 1;
}

// form check
.form-check-lg{
  .form-check-input{
    height: 2em;
    width: 4em;
    margin-top: 0.15em;
  }
} 

// Logo
footer{
  .footer-brand-item {
    height: $navbar-brand-height;
  }
}

// Error title
.error-404-title {
  font-size: 15rem;
}
@include media-breakpoint-down(md) {
  .error-404-title {
    font-size: 5rem;
  }
}

// pre loader
.preloader {
  background-color: $white;
  position: fixed;
  z-index: 1190;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  .preloader-item {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
  }
}